import React from "react"
import { Modal } from "../modal"
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/solid"

export interface IUpsellModalProps {
    cancelButtonText?: string
    confirmButtonText?: string
    handleCancel: () => void
    handleConfirm: () => void
    isOpen: boolean
    text: string,
}

export const UpsellModal = (props: IUpsellModalProps) => {

    return (
        <Modal
            closeModal={() => {}}
            isOpen={props.isOpen}
            widthClassName="max-w-30vw"
        >
            <div className="bg-white pt-2 pb-4 px-6 text-gray-800">
                <div className="flex justify-center mb-2">
                    <div className="flex flex-row">
                        <ChatBubbleLeftEllipsisIcon width={24} height={24} />
                        <p className="ml-2 font-semibold">Ask the customer...</p>
                    </div>
                </div>
                <div className="mt-4 leading-5 text-center">
                    {props.text}
                </div>
                <div className="flex flex-row justify-around mt-8">
                    <button
                        className="w-2/5 justify-center border border-gray-500 hover:bg-filteringPillsHover hover:text-white inline-flex items-center py-2 text-sm font-medium rounded-full"
                        onClick={async () => props.handleCancel()}
                    >
                        {props.cancelButtonText || "NO"}
                    </button>
                    <button
                        className="w-2/5 justify-center text-white bg-horizonred hover:bg-horizonhover hover:text-grey-300 inline-flex items-center py-2 text-sm font-medium rounded-full"
                        onClick={async () => props.handleConfirm()}
                    >
                        {props.confirmButtonText || "YES"}
                    </button>
                </div>
            </div>
        </Modal>
    )
}