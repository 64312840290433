import moment from "moment"

const currencyFormatter = Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" })

export const getCustomerInsuranceStatus = (customer: any) => {
    const returnValue = {
        hasInsurance: customer.hasInsurance === 'true',
        renewalDate: customer.insuranceRenewalDate,
        renewalDateFormatted: "Unknown",
        isRenewalDateUpcoming: false,
        isRenewalDateExpired: false,
        insuranceCover: 0,
        insuranceCoverFormatted: "Unknown",
        accidentalDamageWaiverPercentage: customer.accidentalDamageWaiverPercentage,
        theftAndLossWaiverPercentage: customer.theftAndLossWaiverPercentage,
    }

    const renewalDate = moment(customer.insuranceRenewalDate)

    if (renewalDate.isValid()) {
        returnValue.isRenewalDateExpired = renewalDate < moment()
        returnValue.isRenewalDateUpcoming = renewalDate < moment().add(30, "days")
        returnValue.renewalDateFormatted = renewalDate.format("DD/MM/YYYY")
    }

    const insuranceCoverValue = parseInt(customer.insuranceCover)

    if (!isNaN(insuranceCoverValue)) {
        returnValue.insuranceCover = insuranceCoverValue
        returnValue.insuranceCoverFormatted = currencyFormatter.format(insuranceCoverValue)
    }

    return returnValue
}