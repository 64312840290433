import React from "react"
import { Modal } from "../modal"
import { Button } from "../button"

interface IAddSalesItemModalProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    salesItemList: Record<string, any>[]
    onAddItem: (salesItem: Record<string, any>) => void
}

export const AddSalesItemModal = (props: IAddSalesItemModalProps) => {

    const closeModal = () => {
        props.setIsOpen(false)
    }

    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={closeModal}
            title="ADD SALES ITEM"
            widthClassName="w-auto"
            children={
                <>
                    <div className="flex flex-row">
                        <div className="flex flex-col">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-300">
                                        <tr>
                                            <th className="px-2 text-left">Category</th>
                                            <th className="px-2 text-left">Stock Number</th>
                                            <th className="px-2 text-left">Description</th>
                                            <th className="px-4 text-center">XH?</th>
                                            <th className="px-2 text-center">Unit Price (£)</th>
                                            <th className="px-4">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.salesItemList.map((salesItem: Record<string, any>, index: number) => (
                                            <tr 
                                                key={`add-sales-item-${index}`}
                                                className="even:bg-gray-100 odd:bg-white"
                                            >
                                                <td className="px-2 text-left">{salesItem.equipmentCategory}</td>
                                                <td className="px-2 text-left">{salesItem.stockNumber}</td>
                                                <td className="px-2 text-left">{salesItem.equipmentDesc}</td>
                                                <td className="px-4 text-center">{salesItem.isXH ? <>&#10003;</> : ""}</td>
                                                <td className="px-2 text-center">{`£${salesItem.unitCharge.toFixed(2)}`}</td>
                                                <td className="px-4">
                                                    <button
                                                        className="px-4 py-1 my-1 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:bg-filteringPillsHover hover:text-white"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            props.onAddItem(salesItem)
                                                        }}
                                                    >
                                                        Add
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-center justify-center mt-8">
                        <Button outline onClick={closeModal}>
                            CLOSE
                        </Button>
                    </div>
                </>
            }
        />
    )
}