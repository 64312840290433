import React from "react"
import CurrencyInput from "react-currency-input-field"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"

interface ISalesDetailsFormProps {
    salesDetailsFormValuesArr: Record<string, any>[]
    handleSalesDetailArrChange: (
        id: string,
        key: string,
        value: string | boolean | number | Record<string, any> | null
    ) => void
    addOrDeleteSalesDetailItem: (actionType: string, id?: number) => void
}

export const SalesDetailsForm = ({
    salesDetailsFormValuesArr,
    handleSalesDetailArrChange,
    addOrDeleteSalesDetailItem,
}: ISalesDetailsFormProps) => {
    const {
        description,
        xh,
        quantity,
        unitPrice,
    } = useResourceStringsNewContracts()

    const transitionClassNames = {
        enter: "opacity-0",
        enterActive: "opacity-100 transition-opacity duration-500 ease-in",
        exit: "opacity-100",
        exitActive: "opacity-5 transition-opacity duration-500 ease-out",
    }

    return salesDetailsFormValuesArr.length > 0
        ? (
            <div className={`max-h-[640px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 shadow-inner rounded-md mb-5 p-2 pr-3`}>
                <TransitionGroup>
                    {salesDetailsFormValuesArr.map((salesItem, index) => {
                        return (
                            <CSSTransition
                                key={salesItem.id}
                                timeout={500}
                                classNames={transitionClassNames}
                            >
                                <div className={`bg-white border rounded-md${index > 0 ? " mt-4" : ""}`}>
                                    <div className="px-4">
                                        <div className="flex justify-end pt-3 font-bold">
                                            <button
                                                onClick={() => {
                                                    addOrDeleteSalesDetailItem("delete", salesItem.id)
                                                }}
                                            >
                                                X
                                            </button>
                                        </div>
                                        <div className="grid w-full grid-cols-1 gap-4 mb-3 sm:grid-cols-5 md:grid-cols-10">
                                            <div className="flex flex-col space-y-2 col-span-4">
                                                <label
                                                    className="text-sm font-bold truncate"
                                                    htmlFor={`description-${salesItem.id}`}
                                                >
                                                    {description}
                                                </label>
                                                <input
                                                    id={`description-${salesItem.id}`}
                                                    value={salesItem.equipmentDesc}
                                                    onChange={e =>
                                                        handleSalesDetailArrChange(
                                                            salesItem.id,
                                                            "equipmentDesc",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder={description}
                                                    className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2">
                                                <label
                                                    className="text-sm font-bold truncate"
                                                    htmlFor={`xh-${salesItem.id}`}
                                                >
                                                    {xh}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id={`xh-${salesItem.id}`}
                                                    name="xh"
                                                    checked={salesItem.isXH}
                                                    className=" w-[38px] h-[38px]"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2">
                                                <label
                                                    className="text-sm font-bold truncate"
                                                    htmlFor={`quantity-${salesItem.id}`}
                                                >
                                                    {quantity}
                                                </label>
                                                <input
                                                    id={`quantity-${salesItem.id}`}
                                                    type="text"
                                                    value={salesItem.quantity}
                                                    onChange={e => {
                                                        const quantity = e.target.value
                                                            .replace(/\D/g, "")
                                                            .replace(/^0+/, "")
                                                        handleSalesDetailArrChange(
                                                            salesItem.id,
                                                            "quantity",
                                                            quantity
                                                        )
                                                    }}
                                                    onMouseOut={() => {
                                                        if (!salesItem.quantity)
                                                            handleSalesDetailArrChange(
                                                                salesItem.id,
                                                                "quantity",
                                                                "1"
                                                            )
                                                    }}
                                                    onBlur={() => {
                                                        if (!salesItem.quantity)
                                                            handleSalesDetailArrChange(
                                                                salesItem.id,
                                                                "quantity",
                                                                "1"
                                                            )
                                                    }}
                                                    placeholder={quantity}
                                                    className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2 col-span-2">
                                                <div className="flex flex-row justify-between">
                                                    <label
                                                        className="text-sm font-bold truncate"
                                                        htmlFor={`unitPrice-${salesItem.id}`}
                                                    >
                                                        {unitPrice}
                                                    </label>
                                                </div>
                                                <CurrencyInput
                                                    id={`unitPrice-${salesItem.id}`}
                                                    name={`unitPrice-${salesItem.id}`}
                                                    placeholder={unitPrice}
                                                    value={salesItem.unitCharge}
                                                    prefix="£"
                                                    decimalsLimit={2}
                                                    decimalScale={2}
                                                    onValueChange={(value = "") => {
                                                        handleSalesDetailArrChange(
                                                            salesItem.id,
                                                            "unitCharge",
                                                            value
                                                        )
                                                    }}
                                                    allowNegativeValue={false}
                                                    className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2 col-span-2">
                                                <label
                                                    className="text-sm font-bold truncate"
                                                    htmlFor={`salesCharge-${salesItem.id}`}
                                                >
                                                    Charge
                                                </label>
                                                <CurrencyInput
                                                    id={`salesCharge-${salesItem.id}`}
                                                    name={`salesCharge-${salesItem.id}`}
                                                    placeholder="Charge"
                                                    prefix="£"
                                                    value={salesItem.chargeAmount}
                                                    decimalsLimit={2}
                                                    decimalScale={2}
                                                    allowNegativeValue={false}
                                                    className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        {salesItem.supplierPO && (
                                            <div className="flex flex-row items-center mb-3">
                                                <div className="flex flex-col">
                                                    <svg
                                                        className="w-6 h-6 mr-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                    >
                                                        <path
                                                            d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm0 319.91a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.94v-.05a21.74 21.74 0 1143.44 0z"
                                                            fill="#e18728"
                                                            stroke="#fed7aa"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="flex flex-col text-sm font-semibold">
                                                    {`Purchase order ${salesItem.supplierPO} has been ${salesItem.supplierPOIsSent === "Y" ? "sent to" : "raised for"} ${salesItem.supplierName} (${salesItem.supplierContactTelephone}) for this item`}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            </div>
        )
        : (
            <div className="mb-5 bg-white border rounded-md p-2">No sales items found</div>
        )
}