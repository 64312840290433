import React, { useEffect, useState } from "react"
import Select, { components } from "react-select"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { CircleButton } from "../circle-button"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"
import CurrencyInput from "react-currency-input-field"
import { hirePeriodPriceKeyMap } from "../../utils/contract-helper"
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import moment from "moment"
import { UpsellPopover } from "../upsell-popover"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

interface HireDetailsFormProps {
  equipmentListLoading: boolean
  hirePeriodOptions: Record<string, any>[]
  equipmentList: Record<string, any>[]
  hireDetailsFormValuesArr: Record<string, any>[]
  handleHireDetailArrChange: (
    id: string,
    key: string,
    value: string | boolean | number | Record<string, any> | null
  ) => void
  addOrDeleteHireDetailItem: (actionType: string, id?: number) => void
  getPreviousEquipmentChargesByEquipment: (equipmentCode: string, chargePeriod: string) => void
  previousChargesByEquipment: Record<string, any>
  handleDeliveryDetailChange: (
    key: string,
    value: string | boolean | number | Record<string, any>
  ) => void,
  disableAddHireDetailItem: boolean,
  totalHireDays: number | null,
  upsellItem?: Record<string, any>,
}

interface PricingTypeElement {
  id: any,
  label: React.JSX.Element | null
}

export const HireDetailsForm = ({
  equipmentListLoading,
  hirePeriodOptions,
  equipmentList,
  hireDetailsFormValuesArr,
  handleHireDetailArrChange,
  addOrDeleteHireDetailItem,
  getPreviousEquipmentChargesByEquipment,
  previousChargesByEquipment,
  handleDeliveryDetailChange,
  disableAddHireDetailItem,
  totalHireDays,
  upsellItem,
}: HireDetailsFormProps) => {
  const [pricingTypes, setPricingTypes] = useState<PricingTypeElement[]>([])
  const [previousChargesVisible, setPreviousChargesVisible] = useState<string[]>([])

  const {
    equipmentCode,
    description,
    xh,
    quantity,
    unitPrice,
    hirePeriod,
    hireCharge,
  } = useResourceStringsNewContracts()

  const transitionClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in",
    exit: "opacity-100",
    exitActive: "opacity-5 transition-opacity duration-500 ease-out",
  }

  const getPricingType = (hireItem: any) => {
    if (hireItem?.unitPrice && hireItem?.equipment) {
      const pricingKey = hirePeriodPriceKeyMap[hireItem.hirePeriod?.value]

      if (!pricingKey) {
        return null
      }

      if (parseFloat(hireItem.unitPrice) !== parseFloat(hireItem.equipment.value[pricingKey])) {
        // Price does not match the price from Syrinx, so must have been manually changed
        return "Custom"
      } else {
        return hireItem?.equipment?.value?.pricingType
      }
    }

    return null
  }

  const renderPriceType = (hireItem: any) => {
    const pricingType = getPricingType(hireItem)

    if (pricingType) {
      let text = ""
      let className = "text-sm text-center px-2 text-white rounded "

      switch (pricingType) {
        case "PA":
          // Price is from the customer's price agreement
          text = "Agreement"
          className += "bg-teal-600"
          break
        case "LP":
          // Price is list price
          text = "List"
          className += "bg-rose-600"
          break
        default:
          text = "Custom"
          className += "bg-amber-600"
      }

      return (
        <>
          <label className={className}>{text}</label>
        </>
      )
    }

    return null
  }

  const getEquipmentCodeFromHireItem = (hireItem: any) => {
    return hireItem.equipment?.value?.pricingEquipmentClass === "XH"
        ? hireItem.equipment?.value?.pcode?.replace("XH", "")
        : hireItem.equipment?.value?.pricingEquipmentClass
  }

  const getPreviousCharge = (hireItem: any) => {
    const pricingType = getPricingType(hireItem)

    // Only do this for list price
    if (pricingType && pricingType === "LP") {
      const equipmentCode = getEquipmentCodeFromHireItem(hireItem)
      getPreviousEquipmentChargesByEquipment(equipmentCode, hireItem.hirePeriod?.value)
    }
  }

  const onApplyItemChargeClicked = (e: any, id: any, value:number) => {
    e.preventDefault()
    handleHireDetailArrChange(id, "unitPrice", value)
  }

  const onApplyTransportChargeClicked = (e: any, _:any, value:number) => {
    e.preventDefault()
    handleDeliveryDetailChange("transportCharge", value)
  }

  const renderPreviousChargeSetter = (id: string, value: string, onClick: Function) => {
    return (
      <a 
        className="text-blue-600 cursor-pointer" 
        onClick={(e) => 
          onClick(e, id, parseFloat(value).toFixed(2))
        }
      >
        {`£${parseFloat(value).toFixed(2)}`}
      </a>
    )
  }

  const renderTransportCharge = (hireItem: any) => {
    if (hireItem.equipment?.value) {
      let text = "Standard transport charge applicable"

      if (hireItem.equipment?.value && hireItem.equipment.value.additionalTransportCharge) {
        const transportCharge = parseFloat(hireItem.equipment.value.additionalTransportCharge).toFixed(2)
        text = `Category transport charge of £${transportCharge} applicable`
      }
  
      return (
        <label>
          {text}
        </label>
      )
    }
    
    return null
  }

  const renderPreviousCharges = (hireItem: any) => {
    if (hireItem.equipment?.value) {
      const equipmentCode = getEquipmentCodeFromHireItem(hireItem)
      const previousCharges = previousChargesByEquipment[equipmentCode]
      if (previousCharges) {
        const previousChargesForChargePeriod = previousCharges.filter((charges: any) => {return charges.chargePeriod === hireItem.hirePeriod.value})
        if (previousChargesForChargePeriod.length > 0 && previousChargesForChargePeriod[0].charges?.length > 0) {
          const chargeDate = moment(previousChargesForChargePeriod[0].charges[0].startDate)
          return (
            <>
              <div className="flex w-full">
                <label className="text-sm">
                  <span>{`Most recent${chargeDate.isValid() ? ` (${chargeDate.format("MM/YYYY")})` : ""} hire charge for ${equipmentCode} (${hireItem.hirePeriod.label}) was `}</span>
                  {renderPreviousChargeSetter(hireItem.id, previousChargesForChargePeriod[0].charges[0].chargeAmount, onApplyItemChargeClicked)}
                  <span> with </span>
                  {renderPreviousChargeSetter(hireItem.id, previousChargesForChargePeriod[0].charges[0].transportCharge, onApplyTransportChargeClicked)}
                  <span> transport (</span>
                  {previousChargesForChargePeriod[0].charges.length === 1
                    ? <span>no other previous charges</span>
                    : <a 
                        className="underline cursor-pointer"
                        onClick={() => {
                          if (previousChargesVisible.includes(hireItem.id)) {
                            setPreviousChargesVisible(previousChargesVisible.filter(charge => charge !== hireItem.id))
                          } else {
                            setPreviousChargesVisible([
                              ...previousChargesVisible,
                              hireItem.id,
                            ])
                          }
                        }}
                      >
                        {`${previousChargesVisible.includes(hireItem.id) ? "hide" : "show"}${previousChargesForChargePeriod[0].charges.length === 10 ? " ten": ""} most recent hire charges`}
                      </a>
                  }
                  <span>)</span>
                </label>
              </div>
              <SlideDown>
                {previousChargesVisible.includes(hireItem.id) && previousChargesForChargePeriod[0].charges.length > 1 && (
                  <>
                    <div className="flex flex-row w-full text-sm font-bold mt-4 bg-gray-300">
                      <div className="flex flex-col w-[10%] p-0.5">Contract</div>
                      <div className="flex flex-col w-[50%] p-0.5">Site</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">On Hire Date</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">{equipmentCode}</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">Transport</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">XH</div>
                    </div>
                    {previousChargesForChargePeriod[0].charges.map((charge: any) => (
                      <div 
                        key={`previous-charges-${charge.contractId}`}
                        className="flex flex-row w-full text-sm odd:bg-gray-100 even:bg-white">
                          <div className="flex flex-col w-[10%] p-0.5">{charge.contractNo}</div>
                          <div className="flex flex-col w-[50%] p-0.5">{charge.siteName}</div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {moment(charge.startDate).format("DD/MM/YYYY")}
                          </div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {renderPreviousChargeSetter(hireItem.id, charge.chargeAmount, onApplyItemChargeClicked)}
                          </div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {renderPreviousChargeSetter(hireItem.id, charge.transportCharge, onApplyTransportChargeClicked)}  
                          </div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {charge.crossHire ? <>&#10003;</> : <></>}  
                          </div>
                      </div>
                    ))}
                  </>
                )}
              </SlideDown>
            </>
          )
        } else {
          const pricingType = getPricingType(hireItem)
          return (
            <>
              {pricingType === "LP" && (
                <div className="flex flex-row w-full items-center text-rose-600 mb-1">
                  <ExclamationCircleIcon className="w-[24px] h-[24px] ml-[-2px]" />
                  <label className="text-sm font-semibold ml-2">This is list price. Do we need to be more competitive and reduce the price?</label>
                </div>
              )}
              <div className="flex w-full">
                <label className="text-sm">{`No previous hire charges for ${equipmentCode} (${hireItem.hirePeriod.label})`}</label>
              </div>
            </>
          )
        }
      }
    }

    return null    
  }

  useEffect(() => {
    let pricingTypes:PricingTypeElement[] = []

    hireDetailsFormValuesArr.map((item) => {
      getPreviousCharge(item)
      pricingTypes.push({ 
        id: item.id, 
        label: renderPriceType(item),
      })
    })

    setPricingTypes(pricingTypes)
  }, [hireDetailsFormValuesArr])

  if (equipmentListLoading) return (
    <div className="flex items-center justify-center p-20 mb-5 bg-white border rounded-md">
      <span className='px-3 animate-pulse'>Loading equipment list...</span>
    </div>
  )

  return equipmentList?.length > 0 ? (
    <div className={`max-h-[640px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 shadow-inner rounded-md mb-5 pb-5 p-2 pr-3`}>
      <TransitionGroup>
        {hireDetailsFormValuesArr.map(hireItem => {
          const isPOSent = hireItem?.supplierPOIsSent === "Y"
          const disableInputs = !hireItem?.equipment?.value
          const isFuelCategory = hireItem?.equipment?.value?.pricingEquipmentClass === 'FUEL'
          const { fuelItem } = hireItem || {}

          // Only show the number of hire days if we haven't got a specific number of days for the hire period
          const showTotalHireDays = 
            totalHireDays && 
            (hireItem.hirePeriod.value.toString().endsWith("WEEK") || hireItem.hirePeriod.value.toString().endsWith("MONTH"))
          
            return (
                <CSSTransition
                  key={hireItem.id}
                  timeout={500}
                  classNames={transitionClassNames}
                >
                  <div className="mb-5 bg-white border rounded-md">
                    <div className="px-4 pb-4">
                      <div className="flex justify-end pt-3 font-bold">
                        {hireDetailsFormValuesArr.length > 1 && !isPOSent &&  (
                          <button
                            onClick={() => {
                              addOrDeleteHireDetailItem("delete", hireItem.id)
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                      <div className="grid w-full grid-cols-1 gap-4 mb-3 sm:grid-cols-2 md:grid-cols-2">
                        <div className="flex flex-col space-y-2">
                          <div className="flex flex-row w-full">
                            <div className="flex flex-col">
                              <label
                                className="text-sm font-bold truncate"
                                htmlFor={`equipmentCode-${hireItem.id}`}
                              >
                                {equipmentCode}
                              </label>
                            </div>
                            <div className="flex flex-col flex-grow items-end text-xs">
                              {renderTransportCharge(hireItem)}
                            </div>
                          </div>
                          
                          <Select
                            id={`equipmentCode-${hireItem.id}`}
                            value={hireItem.equipment}
                            onChange={codeObj =>
                              handleHireDetailArrChange(
                                hireItem.id,
                                "equipment",
                                codeObj
                              )
                            }
                            options={equipmentList}
                            placeholder={equipmentCode}
                            isClearable={!isPOSent}
                            className="text-sm"
                            menuPlacement="auto"
                            maxMenuHeight={180}
                            components={{ SingleValue: (props) => {
                              const { pricingEquipmentClass, pcode } = props?.data?.value || {}
                              return <components.SingleValue {...props}>{pricingEquipmentClass} {pcode}</components.SingleValue>
                            }}}
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label
                            className="text-sm font-bold truncate"
                            htmlFor={`description-${hireItem.id}`}
                          >
                            {description}
                          </label>
                          <input
                            id={`description-${hireItem.id}`}
                            value={hireItem.description}
                            onChange={e =>
                              handleHireDetailArrChange(
                                hireItem.id,
                                "description",
                                e.target.value
                              )
                            }
                            placeholder={description}
                            className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={disableInputs}
                          />
                        </div>
                      </div>
                      <div className="grid w-full grid-cols-1 gap-4 mb-3 sm:grid-cols-2 md:grid-cols-5">
                        <div className="flex flex-col space-y-2">
                          <label
                            className="text-sm font-bold truncate"
                            htmlFor={`xh-${hireItem.id}`}
                          >
                            {xh}
                          </label>
                          <input
                            type="checkbox"
                            id={`xh-${hireItem.id}`}
                            name="xh"
                            checked={hireItem.xh}
                            onChange={() => {
                              handleHireDetailArrChange(
                                hireItem.id,
                                "xh",
                                !hireItem.xh
                              )
                            }}
                            className=" w-[38px] h-[38px]"
                            disabled={disableInputs}
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label
                            className="text-sm font-bold truncate"
                            htmlFor={`quantity-${hireItem.id}`}
                          >
                            {quantity}
                          </label>
                          <input
                            id={`quantity-${hireItem.id}`}
                            type="text"
                            value={hireItem.quantity}
                            onChange={e => {
                              const quantity = e.target.value
                                .replace(/\D/g, "")
                                .replace(/^0+/, "")
                              handleHireDetailArrChange(
                                hireItem.id,
                                "quantity",
                                quantity
                              )
                            }}
                            onMouseOut={() => {
                              if (!disableInputs && !hireItem.quantity)
                                handleHireDetailArrChange(
                                  hireItem.id,
                                  "quantity",
                                  "1"
                                )
                            }}
                            onBlur={() => {
                              if (!disableInputs && !hireItem.quantity)
                                handleHireDetailArrChange(
                                  hireItem.id,
                                  "quantity",
                                  "1"
                                )
                            }}
                            placeholder={quantity}
                            className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={disableInputs || isPOSent}
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <div className="flex flex-row justify-between">
                            <label
                              className="text-sm font-bold truncate"
                              htmlFor={`unitPrice-${hireItem.id}`}
                            >
                              {unitPrice}
                            </label>
                            {pricingTypes.find(pricingType => pricingType.id === hireItem.id)?.label}
                          </div>
                          <CurrencyInput
                            id={`unitPrice-${hireItem.id}`}
                            name={`unitPrice-${hireItem.id}`}
                            placeholder={unitPrice}
                            value={hireItem.unitPrice}
                            prefix="£"
                            decimalsLimit={2}
                            decimalScale={2}
                            onValueChange={(value = "") => {
                              handleHireDetailArrChange(
                                hireItem.id,
                                "unitPrice",
                                value
                              )
                            }}
                            allowNegativeValue={false}
                            className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={disableInputs}
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label
                            className="text-sm font-bold truncate"
                            htmlFor={`hirePeriod-${hireItem.id}`}
                          >
                            {hirePeriod}
                          </label>
                          <Select
                            id={`hirePeriod-${hireItem.id}`}
                            value={hireItem.hirePeriod}
                            onChange={hirePeriod =>
                              handleHireDetailArrChange(
                                hireItem.id,
                                "hirePeriod",
                                hirePeriod
                              )
                            }
                            options={hirePeriodOptions}
                            placeholder={hirePeriod}
                            isDisabled={disableInputs || isFuelCategory}
                            className="text-sm"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label
                            className="text-sm font-bold truncate"
                            htmlFor={`hireCharge-${hireItem.id}`}
                          >
                            {`${hireCharge}${showTotalHireDays ? ` (${totalHireDays} day${totalHireDays === 1 ? "" : "s"})`: ""}`}
                          </label>
                          <CurrencyInput
                            id={`hireCharge-${hireItem.id}`}
                            name={`hireCharge-${hireItem.id}`}
                            placeholder={hireCharge}
                            prefix="£"
                            value={hireItem.hireCharge}
                            decimalsLimit={2}
                            decimalScale={2}
                            onValueChange={(value = "") => {
                              handleHireDetailArrChange(
                                hireItem.id,
                                "hireCharge",
                                value
                              )
                            }}
                            allowNegativeValue={false}
                            className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled
                          />
                        </div>
                      </div>

                      {hireItem.supplierPO && (
                        <div className="flex flex-row items-center">
                          <div className="flex flex-col">
                            <svg
                              className="w-6 h-6 mr-1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm0 319.91a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.94v-.05a21.74 21.74 0 1143.44 0z"
                                fill="#e18728"
                                stroke="#fed7aa"
                              />
                            </svg>
                          </div>
                          <div className="flex flex-col text-sm font-semibold">
                            {`Purchase order ${hireItem.supplierPO} has been ${hireItem.supplierPOIsSent === "Y" ? "sent to": "raised for"} ${hireItem.supplierName} (${hireItem.supplierContactTelephone}) for this item`}
                          </div>
                        </div>
                      )}

                      {renderPreviousCharges(hireItem)}
                      {
                        fuelItem && (
                          <div>
                            <hr className="h-px mt-4 mb-3 bg-gray-200 border-0 dark:bg-gray" />
                            <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-5">
                              <div className="flex flex-col space-y-2 text-sm">
                                <span className="pb-0 font-bold">Fuel</span>
                                <div className="flex flex-col flex-grow justify-center">
                                  <span>{fuelItem.stockNumber}</span>
                                </div>
                              </div>
                              <div className="flex flex-col space-y-2 text-sm">
                                <label
                                  className="text-sm font-bold truncate"
                                  htmlFor={`fuel-quantity-${hireItem.id}`}
                                >
                                  {quantity}
                                </label>
                                <input
                                  id={`fuel-quantity-${hireItem.id}`}
                                  type="text"
                                  value={fuelItem.quantity}
                                  onChange={e => {
                                    const quantity = e.target.value
                                      .replace(/\D/g, "")
                                      .replace(/^0+/, "")
                                    handleHireDetailArrChange(
                                      hireItem.id,
                                      "fuel-quantity",
                                      quantity
                                    )
                                  }}
                                  placeholder={quantity}
                                  className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  disabled={disableInputs}
                                />
                              </div>
                              <div className="flex flex-col space-y-2 text-sm">
                                <span className="pb-0 font-bold">Description</span>
                                <div className="flex flex-col flex-grow justify-center">
                                  <span>{fuelItem.equipmentDesc}</span>
                                </div>
                              </div>
                              <div className="flex flex-col space-y-2 text-sm">
                                <label
                                  className="text-sm font-bold truncate"
                                  htmlFor={`fuel-unit-charge-${hireItem.id}`}
                                >
                                  {"Price Per Unit"}
                                </label>
                                <CurrencyInput
                                  id={`fuel-unit-charge-${hireItem.id}`}
                                  name={`fuel-unit-charge-${hireItem.id}`}
                                  placeholder={"Charge"}
                                  value={fuelItem.unitCharge}
                                  onValueChange={(value = "") => {
                                    handleHireDetailArrChange(
                                      hireItem.id,
                                      "fuel-unitCharge",
                                      value
                                    )
                                  }}
                                  prefix="£"
                                  decimalsLimit={2}
                                  decimalScale={2}
                                  allowNegativeValue={false}
                                  className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  disabled={disableInputs}
                                />
                              </div>
                              <div className="flex flex-col space-y-2 text-sm">
                                <label
                                  className="text-sm font-bold truncate"
                                  htmlFor={`fuel-charge-${hireItem.id}`}
                                >
                                  {"Fuel Charge"}
                                </label>
                                <CurrencyInput
                                  id={`fuel-charge-${hireItem.id}`}
                                  name={`fuel-charge-${hireItem.id}`}
                                  placeholder={"Charge"}
                                  value={fuelItem.chargeAmount || 0}
                                  prefix="£"
                                  decimalsLimit={2}
                                  decimalScale={2}
                                  allowNegativeValue={false}
                                  className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  disabled
                                />
                              </div>
                            </div>
                            {fuelItem.stockNumber === "RGASCHARGE" && (
                              <div className="flex flex-row mt-3 w-full justify-end">
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    handleHireDetailArrChange(
                                      hireItem.id,
                                      "fuelItem",
                                      null
                                    )
                                  }}
                                  className="underline sm:text-xs"
                                >
                                  Remove Fuel
                                </button>
                              </div>
                            )}
                          </div>
                        )
                      }
                    </div>
                    <UpsellPopover 
                      anchor={`#equipmentCode-${upsellItem?.id}`}
                      handleCancel={upsellItem?.handleCancel}
                      handleConfirm={upsellItem?.handleConfirm}
                      isOpen={(upsellItem?.id || "") === hireItem.id}
                      side="left"
                      text={upsellItem?.text}
                    />
                  </div>
                </CSSTransition>
          )
        })}
      </TransitionGroup>
      {!disableAddHireDetailItem && (
        <CircleButton
          onClick={() => {
            addOrDeleteHireDetailItem("add")
          }}
        >
          Add
        </CircleButton>
      )}
    </div>
  ) : (
    <div className="mb-5 bg-white border rounded-md p-2">No equipment list found</div>
  )
}
