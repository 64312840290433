import React from "react"

export interface IContractInsuranceProps {
    customerInsuranceStatus: Record<string, any> | null
    contractInsuranceDetails: Record<string, any> | null
    handleUpdateContractInsuranceDetails: (key: string, value: any) => void
}

export const ContractInsurance = (props: IContractInsuranceProps) => {
    return (
        <div className="mb-5 bg-white border rounded-md">
            <div className="flex flex-row px-4 py-4 text-sm">
                <div className="flex flex-col w-2/5 justify-center">
                    {props.customerInsuranceStatus && props.customerInsuranceStatus.hasInsurance ? (
                        <>
                            <div className="flex flex-row w-full font-semibold">
                                <p>The customer has hire insurance</p>
                            </div>
                            <div className="flex flex-row w-full mt-1">
                                <div className="flex flex-col mr-2">
                                    <p>Cover amount: {props.customerInsuranceStatus.insuranceCoverFormatted}</p>
                                </div>
                                <div className="flex flex-col">
                                    <p>
                                        Renewal date:&nbsp;
                                        <span className={props.customerInsuranceStatus.isRenewalDateExpired
                                            ? "font-semibold text-horizonred"
                                            : props.customerInsuranceStatus.isRenewalDateUpcoming
                                                ? "font-semibold text-warning"
                                                : ""
                                        }>
                                            {props.customerInsuranceStatus.renewalDateFormatted}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p className="font-semibold text-horizonred uppercase">
                            The customer does not have hire insurance
                        </p>
                    )}
                </div>
                <div className="flex flex-col w-3/5 justify-center">
                    <div className="flex flex-row w-full justify-evenly">
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center">
                                <input
                                    type="checkbox"
                                    id="hasDamageWaiver"
                                    name="hasDamageWaiver"
                                    checked={props.contractInsuranceDetails?.hasAccidentalDamageWaiver}
                                    onChange={() => {
                                        props.handleUpdateContractInsuranceDetails(
                                            "hasAccidentalDamageWaiver",
                                            !props.contractInsuranceDetails?.hasAccidentalDamageWaiver
                                        )
                                    }}
                                    className=" w-[38px] h-[38px] self-center"
                                />
                                <label
                                    className="text-sm font-bold text-center truncate ml-3"
                                    htmlFor="addDamageWaiver"
                                >
                                    Damage Waiver at {props.contractInsuranceDetails?.accidentalDamageWaiverPercentage}%
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center">
                                <input
                                    type="checkbox"
                                    id="hasTheftLossWaiver"
                                    name="hasTheftLossWaiver"
                                    checked={props.contractInsuranceDetails?.hasTheftLossWaiver}
                                    onChange={() => {
                                        props.handleUpdateContractInsuranceDetails(
                                            "hasTheftLossWaiver",
                                            !props.contractInsuranceDetails?.hasTheftLossWaiver
                                        )
                                    }}
                                    className=" w-[38px] h-[38px] self-center"
                                />
                                <label
                                    className="text-sm font-bold text-center truncate ml-3"
                                    htmlFor="hasTheftLossWaiver"
                                >
                                    Theft &amp; Loss Waiver at {props.contractInsuranceDetails?.theftLossWaiverPercentage}%
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}