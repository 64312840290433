import React from "react"
import { PlacesType, Tooltip } from 'react-tooltip'
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/solid"

export interface IUpsellPopoverProps {
    isAskCustomer?: boolean
    anchor: string
    cancelButtonText?: string
    confirmButtonText?: string
    handleCancel: () => void
    handleConfirm: () => void
    isOpen: boolean
    side: string
    text?: string
    children?: JSX.Element
}

export const UpsellPopover = ({
    anchor,
    cancelButtonText = "NO",
    confirmButtonText = "YES",
    handleCancel,
    handleConfirm,
    isOpen,
    side,
    text,
}: IUpsellPopoverProps) => {
    return (
        <Tooltip
            anchorSelect={anchor}
            clickable={true}
            openEvents={{}} // Prevents open on hover
            place={side as PlacesType}
            isOpen={isOpen}
            classNameArrow="arrow"
            className="max-w-prose !bg-white !box-border !border-2 !border-gray-600 !rounded !opacity-100 !z-[999] [&>.arrow]:border-r-2 [&>.arrow]:border-b-2 [&>.arrow]:border-gray-600 [&>.arrow]:m-[-2px]"
        >
            <div className="bg-white pt-2 pb-4 px-6 text-gray-800 transition duration-150">
                <div className="flex justify-center mb-2">
                    <div className="flex flex-row">
                        <ChatBubbleLeftEllipsisIcon width={24} height={24} />
                        <p className="ml-2 font-semibold">Ask the customer...</p>
                    </div>
                </div>
                <div className="mt-4 leading-5 text-center">
                    {text}
                </div>
                <div className="flex flex-row justify-around mt-4">
                    <button
                        className="w-2/5 justify-center border border-gray-500 hover:bg-filteringPillsHover hover:text-white inline-flex items-center py-2 text-sm font-medium rounded-full"
                        onClick={() => handleCancel()}
                    >
                        {cancelButtonText}
                    </button>
                    <button
                        className="w-2/5 justify-center text-white bg-horizonred hover:bg-horizonhover hover:text-grey-300 inline-flex items-center py-2 text-sm font-medium rounded-full"
                        onClick={() => handleConfirm()}
                    >
                        {confirmButtonText}
                    </button>
                </div>
            </div>
        </Tooltip>
    )
}