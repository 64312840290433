import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { useCustomerContext } from "../../store"
import { NewContractButton } from "../../../../../new-contract-button"
import { LoadingFeedback } from "../../../../../loading-feedback"
import { DynamicFilteringMenu } from "../../../../../dynamic-filtering-menu"
import { DynamicTable } from "../../../../../dynamic-table"
import { useResourceStrings } from "../../../use-resource-strings"
import _, { capitalize } from "lodash"
import Moment from "react-moment"
import { Link } from "gatsby"
import { getInvoiceListByCustomerId } from "../../../../../../middleware/middleware-layer"

export default function Invoices(props: any) {
    const { instance, accounts } = useMsal()
    const { customer } = useCustomerContext()
    const { clearButtonTitle } = useResourceStrings()

    const [isLoading, setIsLoading] = useState(true)
    const [invoiceList, setInvoiceList] = useState<Record<string, any>[]>([])
    const [filteredInvoiceList, setFilteredInvoiceList] = useState<Record<string, any>[]>([])
    const [buttons, setButtons] = useState<Record<string, any>[]>([])
    const [selectedButton, setSelectedButton] = useState("all")

    const defaultInputValues = {
        invoiceNo: null,
        invoiceType: null,
        invoiceDateRange: null,
        contractNo: null,
        siteName: null,
        customerRef: null,
        filterButtonValue: null,
    }
    
    const [filterOptionsInputValues, setFilterOptionsInputValues] = useState<Record<string, any>>(defaultInputValues)

    const resetFilterOptionsInputValues = () => {
        setSelectedButton("all")
        setFilterOptionsInputValues(defaultInputValues)
    }

    const onOptionSelected = (key: string) => (value: Record<string, any>) => {
        setFilterOptionsInputValues({
          ...filterOptionsInputValues,
          [key]: value,
        })
    }

    const onDateRangeOptionSelected = (key: string) => (value: Record<string, any>) => {
        if (Array.isArray(value) && value.filter(Boolean).length === 0) {
            setFilterOptionsInputValues({
            ...filterOptionsInputValues,
            [key]: value,
            })
        } else {
            setFilterOptionsInputValues({
            ...filterOptionsInputValues,
            [key]: { label: key, value: value },
            })
        }
    }

    const containsAll = (obj1: any, obj2: any) => {
        return _.every(Object.keys(obj2), function (key: any) {
            if (obj2[key].value instanceof Array) {
                //Date Ranges
                const d1 = new Date(obj2[key].value[0]).setHours(0, 0, 0, 0)
                const d2 = new Date(obj2[key].value[1]).setHours(0, 0, 0, 0)
        
                if (obj2[key].label == "invoiceDateRange") {
                    const sd = new Date(obj1.invoiceDate).setHours(0, 0, 0, 0)
                    if (sd.valueOf() >= d1.valueOf() && sd.valueOf() <= d2.valueOf()) {
                        return true
                    }
                } else {
                    return false
                }
            } else {
                //Everything else
                return _.includes(obj1, obj2[key].value)
            }
        })
    }

    useEffect(() => {
        const filterOptionsValues = Object.values(filterOptionsInputValues).map((value) => {
            return value
        })
        setFilteredInvoiceList(
            invoiceList.filter((invoice: any) => {
                return containsAll(invoice, filterOptionsValues.filter(Boolean))
            })
        )
    }, [filterOptionsInputValues])

    const renderDateCell = (values?: Record<string, any | string>) => {
        if (values && values[0] == null) {
          return
        }
        const valueString = (values && values.join(", ")) || ""
        return <Moment format="DD-MM-YYYY">{valueString}</Moment>
    }

    const columns = [
        {
            id: "invoiceNo",
            name: "Invoice No.",
            valueKeys: ["invoiceNo"],
            className: "text-left",
            renderCell: (values?: Record<string, any | string>) => {
                const invoiceNo = values?.[0] || ""
                return (
                    <Link
                        to={`/cx-dashboard/customer/${props.customerId}/invoices/${invoiceNo}`}
                    >
                        {invoiceNo}
                    </Link>
                )
            },
        },
        {
            id: "invoiceType",
            name: "Type",
            valueKeys: ["invoiceType"],
            className: "text-left",
        },
        {
            id: "invoiceDate",
            name: "Date",
            valueKeys: ["invoiceDate"],
            className: "text-left",
            renderCell: renderDateCell,
        },
        {
            id: "contractNo",
            name: "Contract No.",
            valueKeys: ["contractId"],
            className: "text-left",
        },
        {
            id: "siteName",
            name: "Site Name",
            valueKeys: ["siteName"],
            className: "text-left",
        },
        {
            id: "customerRef",
            name: "Order No.",
            valueKeys: ["customerRef"],
            className: "text-left",
        },
    ]

    const filterOptionsFields = [
        {
            id: "invoiceNo",
            type: "select",
            label: "Invoice No.",
            placeholder: "Invoice No.",
            options: _.orderBy(
                _.uniqBy(
                    invoiceList.map(({ invoiceNo }: Record<string, any>) => ({
                    label: invoiceNo,
                    value: invoiceNo,
                    })),
                    "value"
                ),
                "value"
                ),
            handleOnChange: onOptionSelected("invoiceNo"),
        },
        {
            id: "contractNo",
            type: "select",
            label: "Contract No.",
            placeholder: "Contract No.",
            options: _.orderBy(
                _.uniqBy(
                    invoiceList.map(({ contractId }: Record<string, any>) => ({
                    label: contractId,
                    value: contractId,
                    })),
                    "value"
                ),
                "value"
                ),
            handleOnChange: onOptionSelected("contractNo"),
        },
        {
            id: "customerRef",
            type: "select",
            label: "Order No.",
            placeholder: "Order No.",
            options: _.orderBy(
                _.uniqBy(
                    invoiceList.map(({ customerRef }: Record<string, any>) => ({
                    label: customerRef,
                    value: customerRef,
                    })),
                    "value"
                ),
                "value"
                ),
            handleOnChange: onOptionSelected("customerRef"),
        },
        {
            id: "invoiceDateRange",
            type: "dateRange",
            label: "Date Range",
            placeholder: "Date Range",
            handleOnChange: onDateRangeOptionSelected("invoiceDateRange"),
        },
    ]

    const allButton = {
        name: "all",
        title: "All",
        onClick: onOptionSelected("invoiceType"),
    }

    useEffect(() => {
        async function initialLoad() {
            await getInvoiceListByCustomerId(accounts, instance, props.customerId)
                .then((results: any) => {
                    setInvoiceList(results?.data?.internalGetInvoiceListByCustomerId?.invoices || [])
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        initialLoad()
    }, [])

    useEffect(() => {
        setFilteredInvoiceList(invoiceList)
        setButtons(
            _.orderBy(
                _.uniqBy(
                    invoiceList.map(({ invoiceType }: Record<string, any>) => ({
                        name: invoiceType,
                        title: capitalize(invoiceType),
                        onClick: onOptionSelected("invoiceType")
                    })),
                    "name"
                ),
                "name"
            )
        )
    }, [invoiceList])

    return (
        <div>
            <div className="flex flex-row justify-end">
                <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
                    {customer?.customerName && customer?.customerName.toUpperCase()}
                </h2>
                <NewContractButton 
                    accounts={accounts}
                    className="mt-8"
                    customer={customer}
                    instance={instance}
                />
            </div>
            <LoadingFeedback
                showSpinner={isLoading}
                translucentBackground={true}
            />
            <DynamicFilteringMenu
                clearButtonTitle={clearButtonTitle}
                filterOptionsFields={filterOptionsFields}
                filterOptionsInputValues={filterOptionsInputValues}
                clearFilters={resetFilterOptionsInputValues}
                resetErrors={() => {}}
                topFilteringButtons={buttons}
                topFilteringTitle={"Currently Viewing"}
                setSelectedButton={setSelectedButton}
                selectedButton={selectedButton}
                allFilterButton={allButton}
            />
            <DynamicTable
                columns={columns}
                list={filteredInvoiceList}
                listKey="pcode"
                listLoading={isLoading}
            />
        </div>
    )
}