import React, { ForwardedRef, forwardRef } from "react"
import { AlertBanner } from "../../../../../../alert-banner"
import { AddContractLogEntry } from "../../../../../../add-contract-log-entry"
import { HireInsuranceStatus } from "../../../../../../hire-insurance-status"
import { ContractLogModal } from "../../../../../../contract-log-modal"
import { ContactsListModal } from "../../../../../../contacts-list-modal"
import { getHireItemChargePeriodDescription } from "../../../../../../../utils/contract-helper"

export interface SummaryProps {
  summary: string
  contractType?: string
  customerContract: Record<string, any> | null
  hireDetails: Record<string, any>
  salesDetails: Record<string, any>
  deliveryDetails: Record<string, any>
  orderDetails: Record<string, any>
  insuranceDetails: Record<string, any> | null
  autoNotes: string
  customer: Record<string, any> | null
  excludedPostcode: boolean
  deliveryTimeOptions: Record<string, any>[]
  totalHireDays: number | null
  hirePeriodMismatchDescription: string
}

export const Summary = forwardRef(
  (
    {
      summary,
      contractType,
      customerContract,
      hireDetails,
      salesDetails,
      deliveryDetails,
      orderDetails,
      insuranceDetails,
      autoNotes,
      customer,
      excludedPostcode,
      deliveryTimeOptions,
      totalHireDays,
      hirePeriodMismatchDescription,
    }: SummaryProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      deliveryCharge,
      collectionCharge,
      siteAddress,
      onHireDate,
      offHireDate,
      deliveryDate,
      deliveryTime,
    } = deliveryDetails || {}

    const { addressDirections } = siteAddress?.value || {}

    const deliveryTimeStr = deliveryTimeOptions.find(
      ({ value }) => value === deliveryTime
    )?.label || deliveryTime

    const getItemsDependingOnFuelCategory = (isFuel: boolean) => {
      return hireDetails.filter((item: Record<string, any>) => {
        const { equipment, quantity } = item || {}
        const { pricingEquipmentClass, pcode } = equipment?.value || {}
        const categoryCheck = isFuel
          ? pricingEquipmentClass === "FUEL"
          : pricingEquipmentClass !== "FUEL"
        return (
          pricingEquipmentClass &&
          categoryCheck &&
          pcode &&
          quantity &&
          +quantity > 0
        )
      })
    }

    const items = getItemsDependingOnFuelCategory(false)

    const independentFuelItems = getItemsDependingOnFuelCategory(true)

    const formattedIndependentFuelItems = independentFuelItems.map(
      (item: Record<string, any>) => {
        const { value } = item.equipment || {}
        return {
          equipmentCategory: value?.pricingEquipmentClass,
          stockNumber: value?.pcode,
          parentItemSeq: null,
          sequenceNo: item?.sequenceNo,
          equipmentDesc: value?.equipmentDescription,
          chargePeriod: item?.hirePeriod.value,
          quantity: +item?.quantity,
          taxCode: value?.fuelTaxCode,
          unitCharge: +item?.unitPrice,
          chargeAmount: +item?.hireCharge,
        }
      }
    )

    const hireDetailsExist = !!items && items.length > 0
    const hasFixedDuration = totalHireDays || 0 >= 5

    // If there is a 5WEEK for any item, change wording to "total weekly charge"
    const isWeeklyCharge = !hasFixedDuration && !!items.find((item: Record<string, any>) => {
      return item.hirePeriod.value === "5WEEK"
    })

    const isMonthlyCharge = !hasFixedDuration && !!items.find((item: Record<string, any>) => {
      return item.hirePeriod.value === "MONTH"
    })

    const attachedFuelItems = items
      .filter((item: Record<string, any>) => !!item.fuelItem)
      .map((item: Record<string, any>) => item.fuelItem)
    const fuelItems = [...attachedFuelItems, ...formattedIndependentFuelItems]
    const fuelItemsExist = !!fuelItems && fuelItems.length > 0
    const totalFuelCharge = fuelItems.reduce((total: any, item: any) => {
      const { chargeAmount } = item || {}
      if (chargeAmount) {
        total += parseFloat(chargeAmount)
      }
      return total
    }, 0)

    const totalHireCost = hireDetails.reduce((total: any, item: any) => {
      const { equipment, hireCharge } = item || {}
      const isFuelCategory = equipment?.value?.pricingEquipmentClass === "FUEL"
      if (!isFuelCategory && hireCharge) {
        total += parseFloat(hireCharge)
      }
      return total
    }, 0)

    const totalSalesCost = salesDetails.reduce((total: any, item: any) => {
      const { chargeAmount } = item || ""
      if (chargeAmount) {
        total += parseFloat(chargeAmount)
      }
      return total
    }, 0)

    const calculateTotalCharge = () => {
      let charge = totalHireCost
      if (!(isWeeklyCharge || isMonthlyCharge)) {
        // Add transport if a fixed period
        charge += parseFloat(deliveryCharge ? deliveryCharge : 0) + parseFloat(collectionCharge ? collectionCharge : 0)
        charge += totalSalesCost
      }
      if (hireDetailsExist) {
        if (insuranceDetails?.hasAccidentalDamageWaiver && insuranceDetails?.accidentalDamageWaiverPercentage && insuranceDetails?.accidentalDamageWaiverPercentage > 0) {
          charge += totalHireCost * (insuranceDetails?.accidentalDamageWaiverPercentage / 100)
        }
        if (insuranceDetails?.hasTheftLossWaiver && insuranceDetails?.theftLossWaiverPercentage && insuranceDetails?.theftLossWaiverPercentage > 0) {
          charge += totalHireCost * (insuranceDetails?.theftLossWaiverPercentage / 100)
        }
      }
      return charge
    }

    const totalCharge = calculateTotalCharge()

    return (
      <div className="sticky top-0">
        <div className="flex">
          <h2 className="mt-4 mb-4 text-xl font-bold uppercase">{summary}</h2>
          <AddContractLogEntry
            contractId={customerContract?.contractId}
            depotId={parseInt(customerContract?.contractDepotId)}
            allowQuoteReady={customerContract?.contractType === "Quotation"}
          />
          <ContractLogModal
            contractId={customerContract?.contractId}
          />
          <ContactsListModal
            customerId={customerContract?.customerId}
            customerName={customerContract?.customerName}
            linkClassNames="ml-4"
          />
        </div>
        <div className="bg-white border sm:rounded-lg">
          <div className="p-4 text-sm uppercase" id="contractSummary" ref={ref}>
            <p className="text-xl font-bold uppercase">
              Contract #{customerContract?.contractNo}
            </p>
            <p className="pt-4">- {contractType}</p>

            {customer?.hasInsurance !== "true" && !insuranceDetails?.hasAccidentalDamageWaiver && !insuranceDetails?.hasTheftLossWaiver && (
              <p className="pt-4 font-bold truncate text-red-600">
                - NO INSURANCE
              </p>
            )}

            {hireDetailsExist && (
              <div className="pt-4">
                {items.map(
                  (item: Record<string, any>) => {
                    const { value } = item.equipment || {}
                    return (
                      <p className="uppercase" key={item.id}>
                        {item.description
                          ? value
                            ? `- ${item.quantity} x ${value ? value.pricingEquipmentClass : ""
                            } ${value ? value.pcode : ""} ${totalHireDays ? `for ${totalHireDays} day${totalHireDays === 1 ? "": "s"}`: "open hire"
                            } @ £${item.unitPrice ? parseFloat(item.unitPrice).toFixed(2) : 0
                            } ${getHireItemChargePeriodDescription(item)} = £${parseFloat(item.hireCharge || 0).toFixed(2)}`
                            : ""
                          : ""}
                      </p>
                    )
                  }
                )}
              </div>
            )}
            {hirePeriodMismatchDescription && (
              <p className="pt-2 font-bold truncate text-red-600">
                - {hirePeriodMismatchDescription}
              </p>
            )}
            {!(isWeeklyCharge || isMonthlyCharge) && !!deliveryCharge && (
              <p className="pt-2">- Delivery Charge: £{deliveryCharge}</p>
            )}
            {!(isWeeklyCharge || isMonthlyCharge) && !!collectionCharge && (
              <p>- Collection Charge: £{collectionCharge}</p>
            )}

            {!(isWeeklyCharge || isMonthlyCharge) && !!totalSalesCost && (
              <div className="pt-2">
                {salesDetails.map((salesItem: Record<string, any>, i: number) => (
                  <p key={i}>
                    - {salesItem.quantity} x {salesItem.equipmentDesc}{" "}
                    @ £{parseFloat(salesItem.unitCharge).toFixed(2)} = £
                    {parseFloat(salesItem.chargeAmount).toFixed(2)}
                  </p>
                ))}
              </div>
            )}

            {hireDetailsExist && (
              <div className="pt-4">
                {insuranceDetails?.hasAccidentalDamageWaiver && insuranceDetails?.accidentalDamageWaiverPercentage && insuranceDetails?.accidentalDamageWaiverPercentage > 0 && (
                  <p>
                    - Damage Waiver @{insuranceDetails?.accidentalDamageWaiverPercentage}% of the
                    total hire cost:{" "}
                    {insuranceDetails?.accidentalDamageWaiverPercentage
                      ? `£${(
                        totalHireCost *
                        (insuranceDetails?.accidentalDamageWaiverPercentage / 100)
                      ).toFixed(2)}`
                      : "£0"}
                  </p>
                )}
                {insuranceDetails?.hasTheftLossWaiver && insuranceDetails?.theftLossWaiverPercentage && insuranceDetails?.theftLossWaiverPercentage > 0 && (
                  <p>
                    - Theft & Loss Waiver @{insuranceDetails?.theftLossWaiverPercentage}% of
                    the total hire cost:{" "}
                    {insuranceDetails?.theftLossWaiverPercentage
                      ? `£${(
                        totalHireCost *
                        (insuranceDetails?.theftLossWaiverPercentage / 100)
                      ).toFixed(2)}`
                      : "£0"}
                  </p>
                )}
              </div>
            )}

            <p className="pt-2">
              {parseFloat(totalCharge)
                ? <span className="font-semibold">
                    {`- Total ${isWeeklyCharge ? "weekly" : isMonthlyCharge ? "monthly" : ""} charge: £${parseFloat(totalCharge).toFixed(2)}`}
                </span>
                : ""}
            </p>

            {(isWeeklyCharge || isMonthlyCharge) && !!deliveryCharge && <p className="pt-4">- Delivery Charge: £{deliveryCharge}</p>}
            {(isWeeklyCharge || isMonthlyCharge) && !!collectionCharge && (
              <p>- Collection Charge: £{collectionCharge}</p>
            )}

            {(isWeeklyCharge || isMonthlyCharge) && !!totalSalesCost && (
              <div className="pt-4">
                {salesDetails.map((salesItem: Record<string, any>, i: number) => (
                  <p key={i}>
                    - {salesItem.quantity} x {salesItem.equipmentDesc}{" "}
                    @ £{parseFloat(salesItem.unitCharge).toFixed(2)} = £
                    {parseFloat(salesItem.chargeAmount).toFixed(2)}
                  </p>
                ))}
                <p className="pt-2">
                  <span className="font-semibold">- Total Sales Items Charge: £{totalSalesCost.toFixed(2)}</span>
                </p>
              </div>
            )}

            {fuelItemsExist && (
              <div className="pt-4">
                {fuelItems.map((fuelItem: Record<string, any>, i: number) => (
                  <p key={i}>
                    - {fuelItem.quantity} x {fuelItem.equipmentCategory}{" "}
                    {fuelItem.stockNumber} @ £{parseFloat(fuelItem.unitCharge).toFixed(2)} = £
                    {fuelItem.chargeAmount?.toFixed(2)}
                  </p>
                ))}
                <p className="pt-2">
                  <span className="font-semibold">- Total Fuel Charge: £{totalFuelCharge?.toFixed(2)}</span>
                </p>
              </div>
            )}
            <p className="pt-4">
              {siteAddress?.label ? `- Site address: ${siteAddress.label}` : ""}
            </p>
            {excludedPostcode && (
              <p className="pt-2 font-bold truncate text-red-600">
                - EXCLUSION POSTCODE
              </p>
            )}
            <p className="pt-4">
              {addressDirections ? `- Directions: ${addressDirections}` : ""}
            </p>
            <p className="pt-4">
              {onHireDate ? `- On hire: ${onHireDate.toDateString()}` : ""}
            </p>
            <p className="pt-2">
              {offHireDate
                ? `- Off hire: ${offHireDate.toDateString()} ${totalHireDays ? `(${totalHireDays} days)` : ""}`
                : onHireDate
                  ? "- Off hire: open hire"
                  : ""}
            </p>
            <p className="pt-2">
              {deliveryDate
                ? `- Delivery: ${deliveryDate.toDateString()} ${deliveryTimeStr ? `(${deliveryTimeStr})` : ""
                }`
                : ""}
            </p>
            <p className="pt-4">{autoNotes}</p>
            <p className="pt-4">
              {orderDetails.notes ? `- Notes: ${orderDetails.notes}` : ""}
            </p>
          </div>
        </div>
        <HireInsuranceStatus
          hasInsurance={customer?.hasInsurance}
          insuranceRenewalDate={customer?.insuranceRenewalDate}
          classNames="my-8"
        />
        {customer?.alertText &&
          <div className="mt-6">
            <AlertBanner text={customer?.alertText} />
          </div>
        }
        {customer?.notes &&
          <div className="mt-6">
            <AlertBanner text={customer?.notes} isInfo={true} />
          </div>
        }
      </div>
    )
  }
)
